<template>
  <CRow>
    <CCol :md="size.md_image" v-bind:class="{ 'bg-gray-100': is_modified }">
      <img
        v-if="item.ppm.lot.productproducer.image_cropped"
        v-bind:src="item.ppm.lot.productproducer.image_cropped"
        class="img-fluid"
        :alt="item.ppm.lot.productproducer.translations.it.name"
      />
    </CCol>
    <CCol :md="size.md_text" v-bind:class="{ 'bg-gray-100': is_modified }">
      <h3>{{ item.ppm.lot.productproducer.translations.it.name }}</h3>
      <h5>{{ item.ppm.lot.productproducer.producer.role.name }}</h5>
      <p>{{ item.ppm.lot.translations.it.name }}</p>
    </CCol>
    <CCol
      md="12"
      v-if="size_sm"
      v-bind:class="{ 'bg-gray-100': is_modified, 'text-right': true }"
    >
      <CButtonToolbar
        aria-label="Toolbar with button groups and input groups"
        class="d-sm-down-none"
      >
        <CButtonGroup size="sm" class="mx-1">
          <CInput
            class="mb-0 w-25 mx-1"
            size_="sm"
            type="number"
            v-model="item_qta"
          />

          <!-- <CButton color="secondary">Cancel</CButton> -->
          <div class="mx-4">
            <small>x {{ item.ppm.price }} CHF = </small>
            <br /><b>{{ (item_qta * item.ppm.price).toFixed(2) }} CHF</b>
          </div>
          <div :class="{ 'd-none': !is_modified }">
            <vue-ladda
              :v-if_="is_modified"
              :loading="item_loading"
              data-style="zoom-in"
              button-class="btn btn-success"
              @click.prevent="UpdateCartItem()"
              >{{ $t("user.marketCartLine.update") }}</vue-ladda
            >
          </div>
          <div :class="{ 'd-none': is_modified }">
            <vue-ladda
              :v-if_="!is_modified"
              :loading="item_deleting"
              data-style="zoom-in"
              button-class="btn btn-secondary"
              @click.prevent="DeleteCartItem()"
              ><CIcon name="cil-trash"
            /></vue-ladda>
          </div>
        </CButtonGroup>
      </CButtonToolbar>
    </CCol>
    <CCol md="2" v-if="!size_sm" v-bind:class="{ 'bg-gray-100': is_modified }">
      <CButtonToolbar
        aria-label="Toolbar with button groups and input groups"
        class="d-sm-down-none"
      >
        <CButtonGroup size="sm" class="mx-1">
          <CInput
            class="mb-0 w-50 mx-1"
            size_="sm"
            type="number"
            v-model="item_qta"
          />
          <div :class="{ 'd-none': !is_modified }">
            <vue-ladda
              :v-if_="is_modified"
              :loading="item_loading"
              data-style="zoom-in"
              button-class="btn btn-success"
              @click.prevent="UpdateCartItem()"
              >Aggiorna</vue-ladda
            >
          </div>
          <div :class="{ 'd-none': is_modified }">
            <vue-ladda
              :v-if_="!is_modified"
              :loading="item_deleting"
              data-style="zoom-in"
              button-class="btn btn-secondary"
              @click.prevent="DeleteCartItem()"
              ><CIcon name="cil-trash"
            /></vue-ladda>
          </div>
        </CButtonGroup>
      </CButtonToolbar>
      <br /><small>x {{ (item.ppm.price * 1).toFixed(2) }} CHF</small>
    </CCol>
    <CCol md="2" v-if="!size_sm" v-bind:class="{ 'bg-gray-100': is_modified }">
      <h2>{{ (item.ppm.price * item_qta).toFixed(2) }} CHF</h2>
    </CCol>

    <CCol md="12">
      <hr />
      <!-- {{item}} -->
    </CCol>
  </CRow>
</template>

<script>
import { GetDataManager, ApiCall } from "../../ds/index";
import { Query } from "@syncfusion/ej2-data";
export default {
  data() {
    return {
      item_deleting: false,
      item_loading: false,
      item_qta: this.item.qta,
    };
  },
  model: {
    prop: "item",
    event: "change",
  },
  props: ["item", "size_sm"],
  computed: {
    is_modified() {
      return this.item.qta != this.item_qta;
    },
    cart() {
      return this.$store.state.shop.market_cart;
    },
    cartItem() {
      return this.item;
    },
    size() {
      if (this.size_sm) {
        return {
          md_image: 3,
          md_text: 9,
        };
      } else {
        return {
          md_image: 2,
          md_text: 6,
        };
      }
    },
  },
  watch: {
    "cartItem.id": function () {
      console.log("Update");
      this.item_qta = this.cartItem.qta;
    },
  },
  methods: {
    UpdateCartItem() {
      // update item
      let self = this;
      console.log("Update cart");
      let params = {
        id: this.item.id,
        qta: this.item_qta,
      };
      self.item_loading = true;
      // console.log(params)
      // GetDataManager("cart_usercarts")
      GetDataManager("cart_usercarts_detail", [self.cart.id])
        .update("", params)
        .then(
          (e) => {
            //   self.pp = e;
            // self.$emit("change", e);

            // self.active_loading = false;
            self.$emit("change", e);
            ApiCall(
              // GetDataManager("role_producer_products", [this.$store.state.role.id]),
              GetDataManager("cart_usercarts", [], self.cart.id),
              new Query(),
              function (e) {
                // self.numPages = Math.ceil(e.actual.Count / 12);
                // self.$store.cart.set(e.result);
                // self.$store.dispatch("cart/add", e.result);
                console.log(e.result);
                self.$store.commit("shop/setCart", e.result);
                self.item_loading = false;
              }
            );
          },
          (reason) => {
            let errors = JSON.parse(reason[0].error.response);
            self.item_loading = false;
            console.log(errors);
            // this.pp.is_active = !this.pp.is_active;
            // this.active_loading = false;
            // this.dataForm.errors = errors;
            // this.dataForm.loading = false;
          }
        );
      // refresh cart
    },
    DeleteCartItem() {
      // update item
      let self = this;
      console.log("Delete cart item");
      self.item_deleting = true;
      GetDataManager("cart_usercarts_detail", [self.cart.id])
        .remove("", this.item.id)
        .then(
          () => {
            //   self.pp = e;
            // self.$emit("change", e);

            // self.active_loading = false;
            self.$emit("delete-item");
            ApiCall(
              // GetDataManager("role_producer_products", [this.$store.state.role.id]),
              GetDataManager("cart_usercarts", [], self.cart.id),
              new Query(),
              function (e) {
                // self.numPages = Math.ceil(e.actual.Count / 12);
                // self.$store.cart.set(e.result);
                // self.$store.dispatch("cart/add", e.result);
                console.log(e.result);
                self.$store.commit("shop/setCart", e.result);
                self.item_deleting = false;
              }
            );
          },
          (reason) => {
            let errors = JSON.parse(reason[0].error.response);
            self.item_deleting = false;
            console.log(errors);
            // this.pp.is_active = !this.pp.is_active;
            // this.active_loading = false;
            // this.dataForm.errors = errors;
            // this.dataForm.loading = false;
          }
        );
      // refresh cart
    },
  },
};
</script>
